<template>
	<section class="agent-recovery">
		<div class="container">
			<div class="agent-recovery__wrapper">
				<Breadcrumb :pages="pages" />

				<div class="agent-recovery__body">
					<Transition name="slide-broker" mode="out-in">
						<RecoverPassword
							v-if="!isSubmit"
							@success="() => (isSubmit = true)"
						/>
						<RecoverAlert v-else @prev="() => (isSubmit = false)" />
					</Transition>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import RecoverPassword from '@/modules/broker/components/Recovery/RecoverPassword.vue'
import RecoverAlert from '@/modules/broker/components/Recovery/RecoverAlert.vue'

export default {
	name: 'AgentLogin',
	components: {
		RecoverAlert,
		RecoverPassword,
		Breadcrumb
	},
	data() {
		return {
			isSubmit: false
		}
	},
	computed: {
		pages() {
			return {
				first: {
					title: this.$t('general.home'),
					srcName: 'MainRoute'
				},
				inner: [
					{
						title: this.$t('web.pages_agent_login'),
						srcName: 'Login'
					}
				],
				current: this.$t('web.pages_agent_recovery')
			}
		}
	}
}
</script>

<style lang="scss">
.agent-recovery {
	margin-bottom: 80px;
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
	&__body {
		width: 100%;
		justify-content: center;
		display: flex;
	}
	@media screen and (max-width: 479px) {
		margin-bottom: 0;
	}
}
</style>
